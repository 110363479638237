/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */

import classNames from "classnames";
import React, { useState, useEffect } from "react";
import * as s from "./SingleOrder.module.scss";
import invoice from "../../../images/invoice.svg";

const cn = classNames.bind(s);

const SingleOrder = ({
  order,
  setActiveOrder,
  setOrdersProfileActive,
}: any) => {
  const [orderDay, setOrderDay] = useState<number>();
  const [orderMonth, setOrderMonth] = useState<string>();
  const [orderYear, setOrderYear] = useState<number>();

  useEffect(() => {
    const date = new Date(order?.processedAt);
    setOrderDay(date.getDate());
    setOrderMonth(date.toLocaleString("default", { month: "long" }));
    setOrderYear(date.getFullYear());
  }, [order]);

  const handleClick = () => {
    setActiveOrder(order);
    setOrdersProfileActive("single order");
  };

  return (
    <div className={s.mainWrapper}>
      <p className={s.name}>{order.name}</p>
      <p className={cn(s.date, s.widthProp)}>
        {orderMonth} {orderDay}, {orderYear}
      </p>
      <p className={cn(s.date, s.financ)}>{order.financialStatus}</p>
      <p className={cn(s.date, s.status)}>{order.fulfillmentStatus}</p>
      <p className={s.price}>$ {order.totalPriceV2.amount}</p>
      <div className={s.invoiceWrapper} onClick={() => handleClick()}>
        <img src={invoice} alt="invoice img"></img>
        <p>Invoice</p>
      </div>
    </div>
  );
};

export default SingleOrder;
