import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ADD_TO_CART,
  CART_BUYER_IDENTITY,
  CREATE_CART,
} from "../../../g/mutations/createCart";
import { GET_USER } from "../../../g/queries/get-user-info";
import { cartConstants } from "../../../modules/cart/constants";
import * as s from "./OrderAgain.module.scss";

interface IOrder {
  node: {
    quantity: number;
    title: string;
    variant: {
      id: string;
      priceV2: TroopState.PriceProps;
      image: TroopState.ImagesProps;
      product: {
        handle: string;
      };
    };
  };
}

const OrderAgain = ({ order }: { order: IOrder[] }) => {
  const [cartData, setCartData] = useState<TroopState.EndpointPayload>({});
  const dispatch = useDispatch();
  const [token, setToken] = useState<string | null>(null);
  const [createCart] = useMutation(CREATE_CART);
  const [customer, setCustomer] = useState<TroopState.User>();

  const { data } = useQuery(GET_USER, {
    variables: {
      customerAccessToken: token,
    },
    onCompleted: ({ customer }) => {
      setCustomer(customer);
    },
  });

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);

  useEffect(() => {
    if (cartData.id) {
      dispatch({
        type: cartConstants.ADD_TO_CART_SUCCESS,
        payload: cartData?.lines,
      });
      if (token) {
        const id = localStorage.getItem("cartId");
        associateCustomerToCart(id);
      }
    }
  }, [cartData]);

  const associateCustomerToCart = (id: string | null) => {
    cartAssociate({
      variables: {
        cartId: id,
        buyerIdentityInput: {
          customerAccessToken: token,
          email: customer?.email,
          phone: customer?.phone,
        },
      },
    });
  };

  const [cartAssociate] = useMutation(CART_BUYER_IDENTITY, {});

  const [cartAddItems] = useMutation(ADD_TO_CART, {
    onCompleted: ({ cartLinesAdd }) => {
      setCartData({
        ...cartData,
        ...cartLinesAdd?.cart,
      });
    },
  });

  const handleClick = () => {
    const cartId = localStorage.getItem("cartId");

    if (cartId) {
      addItems(cartId, true);
    } else {
      createCart({
        variables: {
          cartInput: {
            lines: {
              merchandiseId: order[0]?.node?.variant?.id,
              quantity: order[0]?.node?.quantity,
              sellingPlanId: null,
            },
          },
        },
        onCompleted: ({ cartCreate }) => {
          localStorage.setItem("cartId", cartCreate?.cart?.id);
          localStorage.setItem("webUrl", cartCreate?.cart?.checkoutUrl);
          setCartData(cartCreate?.cart);
          if (order?.length > 1) addItems(cartCreate?.cart?.id, false);
        },
      });
    }
  };

  const addItems = (cartId: string, takeFirst: boolean) => {
    order.forEach((element: IOrder, index: number) => {
      if (index !== 0 && !takeFirst) {
        cartAddItems({
          variables: {
            lines: {
              merchandiseId: element?.node?.variant?.id,
              quantity: element?.node?.quantity,
              sellingPlanId: null,
            },
            cartId: cartId,
          },
        });
      } else if (takeFirst) {
        cartAddItems({
          variables: {
            lines: {
              merchandiseId: element?.node?.variant?.id,
              quantity: element?.node?.quantity,
              sellingPlanId: null,
            },
            cartId: cartId,
          },
        });
      }
    });
  };

  return (
    <button className={s.button} onClick={() => handleClick()}>
      Order again
    </button>
  );
};

export default OrderAgain;
