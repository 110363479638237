/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */

import React, { useState, useEffect } from "react";
import * as s from "./ProfilePage.module.scss";
import AccMenu from "../../molecules/AccMenu/AccMenu";
import ProfilePageContent from "../../molecules/ProfilePageContent/ProfilePageContent";
import { navigate } from "gatsby-link";

interface IAddress {
  defaultAddress: {
    address1: string;
    address2: string;
    city: string;
    country: string;
    province: string;
    zip: string;
    phone: string;
  };
  orders: any;
}

interface IProfile {
  customer?: TroopState.User & IAddress;
  checkoutId: string | null;
  token: string | null;
}

const ProfilePage = ({ customer, checkoutId, token }: IProfile) => {
  //this var tracks menu changes
  const [activeComponent, setActiveComponent] = useState("Account");
  const [isLoaded, setIsLoaded] = useState(false);

  const [ordersProfileActive, setOrdersProfileActive] =
    useState<string>("order profile");

  const [activeOrder, setActiveOrder] = useState();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    } else setIsLoaded(true);
  }, []);

  return isLoaded ? (
    <div className={s.container}>
      <p className={s.welcomeText}>
        Welcome, <span>{customer?.firstName}</span>
      </p>

      <div className={s.contentWrapper}>
        <AccMenu
          setActiveComponent={setActiveComponent}
          activeComponent={activeComponent}
          ordersProfileActive={ordersProfileActive}
          setOrdersProfileActive={setOrdersProfileActive}
          customer={customer}
          token={token}
          checkoutId={checkoutId}
          activeOrder={activeOrder}
          setActiveOrder={setActiveOrder}
        />
        {typeof window !== "undefined" && window.screen.width > 768 ? (
          <ProfilePageContent
            activeTitle={activeComponent}
            customer={customer}
            checkoutId={checkoutId}
            token={token}
            setActiveOrder={setActiveOrder}
            ordersProfileActive={ordersProfileActive}
            activeOrder={activeOrder}
            setOrdersProfileActive={setOrdersProfileActive}
          />
        ) : null}
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default ProfilePage;
