import React from "react";
import AccInfo from "../../atoms/AccInfo/AccInfo";
import * as s from "./Account.module.scss";

const Account = ({ customer, checkoutId, token }: any) => {
  return (
    <div className={s.accountWrapper}>
      <div>
        <p className={s.accountInfo}>Account Information</p>
        <AccInfo
          name={customer?.firstName}
          lastname={customer?.lastName}
          email={customer?.email}
          customer={customer}
          activeComponent="edit"
        />
      </div>

      <div>
        <p className={s.accountInfo}>Shipping Information</p>
        <AccInfo
          address={customer?.defaultAddress?.address1}
          city={customer?.defaultAddress?.city}
          zip={customer?.defaultAddress?.zip}
          state={customer?.defaultAddress?.country}
          phone={customer?.defaultAddress?.phone}
          checkoutId={checkoutId}
          token={token}
          activeComponent="shipping"
        />
      </div>
    </div>
  );
};

export default Account;
