/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useEffect, useState } from "react";
import SubscriptionRow from "../../atoms/SubscriptionRow/SubstriptionRow";
import * as s from "./Subscriptions.module.scss";
import plus from "../../../images/Plus circle.svg";
import SmallerPopUp from "../../atoms/SmallerPopUp/SmallerPopUp";
import { InputLabel, MenuItem, Select } from "@mui/material";
import { useGetAllShopifyProducts } from "../../../g/queries/get-all-shopify-products";
import Quantity from "../../atoms/Quantity/Quantity";
import getCurrencySymbol from "currency-symbols";
import { Buffer } from "buffer";
import moment from "moment";
import { toast } from "react-toastify";
import ClockLoader from "react-spinners/ClockLoader";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GET_PLAN_BY_PRODUCT_ID,
  PLANS,
  PLANS_2,
} from "../../../g/queries/get-plans";

const Subscriptions = ({ customer }: any) => {
  const { email } = customer;
  const { allShopifyProduct } = useGetAllShopifyProducts();
  const [subscriptions, setSubscriptions] = useState<any>([]);
  // add new sub
  const [openAddNewPopup, setOpenAddNewPopup] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [subscriptionTotal, setSubscriptionTotal] = useState(0);
  const [newSubItem, setNewSubItem] = useState<ProductNode | null>(null);
  const [isQuantityDisabled, setIsQuantityDisabled] = useState(true);
  const [addressId, setAddressId] = useState<number>();
  // product info
  const [products, setProducts] = useState<ProductNode[]>([]);
  const [discountedPrices, setDiscountedPrices] = useState<any>([]);
  // cancel/pause plan
  const [pausePlanOpen, setPausePlanOpen] = useState(false);
  const [isPlanPlaused, setIsPlanPaused] = useState(false);
  const [resumeDate, setResumeDate] = useState("");
  const [cancelPlanOpen, setCancelPlanOpen] = useState(false);
  const [otherReason, setOtherReason] = useState("");
  // cancel item
  const [shouldRefetch, setShouldRefetch] = useState(false);
  // loading
  const [loading, setLoading] = useState(true);
  const [loadingResponse, setLoadingResponse] = useState(false);

  const initialReasonsArray = [
    {
      index: 0,
      text: "This is too expensive",
      checked: false,
    },
    {
      index: 1,
      text: "This was created by accident",
      checked: false,
    },
    {
      index: 2,
      text: "I already have more than I need",
      checked: false,
    },
    {
      index: 3,
      text: "I need it sooner",
      checked: false,
    },
    {
      index: 4,
      text: "I no longer use this product",
      checked: false,
    },
    {
      index: 5,
      text: "I want a different product or variety",
      checked: false,
    },
  ];

  const [checkReasons, setCheckReasons] = useState(initialReasonsArray);

  const getCustomerSubscriptions = async () => {
    const url = window.location.href.replace("profile", ".netlify/functions");
    const email = customer.email;
    const result = await fetch(
      `${url}/getCustomerSubscriptions?email=${email}`,
      {
        method: "GET",
      }
    );
    if (result.status === 200) {
      try {
        const bodyData = await result.json();
        setAddressId(bodyData.subscriptions[0].address_id);
        const filterSubs = bodyData.subscriptions.filter(
          (el: any) => el.status === "ACTIVE"
        );
        setSubscriptions(filterSubs);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const decodeBase64 = (id: any) => {
    const base64ToString = Buffer.from(id as string, "base64");
    return base64ToString.toString().split("/").pop();
  };

  const updatePlan = async () => {
    if (!newSubItem || !products.length) return;
    // loader
    setLoadingResponse(true);
    const url = window.location.href.replace("profile", ".netlify/functions");
    const productBody = {
      price: newSubItem.node.variants[0].price,
      product_title: newSubItem.node.title,
      quantity: selectedQuantity,
      sku: decodeBase64(newSubItem.node.shopifyId),
    };

    // create subscription first
    const body = {
      address_id: addressId,
      charge_interval_frequency: 30,
      order_interval_frequency: 30,
      order_interval_unit: "day",
      next_charge_scheduled_at: subscriptions.length
        ? subscriptions[0].next_charge_scheduled_at
        : moment(new Date(), "YYYY-MM-DD").add(30, "days").format("YYYY-MM-DD"),
      price: productBody.price,
      product_title: productBody.product_title,
      quantity: productBody.quantity,
      shopify_variant_id: Number(
        decodeBase64(newSubItem.node.variants[0].shopifyId)
      ),
    };

    const result = await fetch(`${url}/createSubscription`, {
      body: JSON.stringify(body),
      method: "POST",
    });

    if (result.status === 200) {
      const data = await result.json();
      setSubscriptions([...subscriptions, data.subscription]);
      resetAddMorePopUp();
    } else {
      console.log("something went wrong");
    }
  };

  const addItemToPlan = (e: any) => {
    setSelectedProduct(e.target.value);
    const product = products.filter(
      (item) => item.node.title === e.target.value
    );
    const isIncluded = false;
    if (product.length && !isIncluded) {
      setNewSubItem(product[0]);
      const discountedPrice = discountedPrices.filter(
        (item: any) =>
          item.title.toUpperCase() === product[0].node.title.toUpperCase()
      )[0].adjustedPrice;
      setSubscriptionTotal(discountedPrice * selectedQuantity);
      setIsQuantityDisabled(false);
    } else if (!product.length) {
      setIsQuantityDisabled(true);
      setSelectedQuantity(0);
    }
  };

  const AddNewSubscriptionForm = () => {
    return (
      <div>
        <div className={s.label}>
          Select products to add <span>*</span>
        </div>

        <div className={s.productsContent}>
          <Select
            value={selectedProduct}
            onChange={(e: any) => addItemToPlan(e)}
            displayEmpty
            className={s.subscriptionSelect}
            inputProps={{
              "aria-label": "Without label",
            }}
            SelectDisplayProps={{
              style: {
                padding: "0 0 0 15px",
                fontFamily: "Neue Radial C Regular",
              },
            }}
            IconComponent={() => <div className={s.downArrow}></div>}
            MenuProps={{
              PaperProps: {
                style: {
                  width: "258px",
                },
              },
              style: {
                left: "10px",
                top: "10px",
              },
            }}
          >
            <MenuItem className={s.menuItem} key="initial-item" value="">
              Select item
            </MenuItem>
            {products.map((item: ProductNode) => (
              <MenuItem
                className={s.menuItem}
                key={item.node.id}
                value={item.node.title}
              >
                <div className={s.menuItemWrapper}>
                  <div className={s.menuItemContainer}>
                    <img
                      alt={`${item.node.title}-new-sub`}
                      src={
                        item.node.images[2]
                          ? item.node.images[2].originalSrc
                          : item.node.images[0].originalSrc
                      }
                    />
                    {item.node.title}
                  </div>
                  <div className={s.menuItemPrice}>
                    {getCurrencySymbol(
                      item.node.variants[0].priceV2.currencyCode
                    )}
                    {discountedPrices.length &&
                      discountedPrices.filter(
                        (i: any) =>
                          i.title.toUpperCase() ===
                          item.node.title.toUpperCase()
                      )[0].adjustedPrice}
                  </div>
                </div>
              </MenuItem>
            ))}
          </Select>

          <Quantity
            disabled={isQuantityDisabled}
            newQuantity={selectedQuantity}
            setNewQuantity={setSelectedQuantity}
          ></Quantity>
        </div>

        <div className={s.separator}></div>

        <div className={s.totalContainer}>
          <div>Total</div>
          <div className={s.totalPrice}>
            {newSubItem
              ? getCurrencySymbol(
                  newSubItem.node.variants[0].priceV2.currencyCode
                )
              : ""}

            {subscriptionTotal}
          </div>
        </div>
      </div>
    );
  };

  const onSelectReason = (item: any, e: any) => {
    const updatedReasonArray = [...checkReasons];
    updatedReasonArray[item.index].checked =
      !updatedReasonArray[item.index].checked;
    setCheckReasons(updatedReasonArray);
  };

  const handlePausePlan = async () => {
    // add info to local storage for showing the correct message
    const resDate = moment(subscriptions[0].next_charge_scheduled_at)
      .add(1, "month")
      .format("MMMM Do YYYY");
    localStorage.setItem("planPaused", "true");
    setPausePlanOpen(false);
    setIsPlanPaused(true);

    setResumeDate(resDate);

    toast("Successfully paused plan.", { type: "success" });

    // update all subscriptions to the same next charge date
    const url = window.location.href.replace("profile", ".netlify/functions");

    for (const item of subscriptions) {
      await fetch(`${url}/updateChargeDate?id=${item.id}`, {
        body: JSON.stringify({
          date: moment(subscriptions[0].next_charge_scheduled_at)
            .add(1, "month")
            .format("YYYY-MM-DD"),
        }),
        method: "POST",
      });
    }
  };

  const handleResumePlan = () => {
    const planPaused = localStorage.getItem("planPaused");
    if (!planPaused) return;

    const currentDate = moment().format("MMMM Do YYYY");
    const resumeDate = moment(subscriptions[0].next_charge_scheduled_at).format(
      "MMMM Do YYYY"
    );
    setResumeDate(resumeDate);
    if (currentDate === resumeDate) {
      localStorage.removeItem("planPaused");
      setIsPlanPaused(false);
      setResumeDate("");
    }
  };

  const handleCancelPlan = async () => {
    setLoadingResponse(true);
    let cancelReasons = "";
    checkReasons.map((item) => {
      if (item.checked) cancelReasons = cancelReasons + " " + item.text;
    });
    cancelReasons = cancelReasons + " " + otherReason;
    // endpoint

    const url = window.location.href.replace("profile", ".netlify/functions");

    for (const item of subscriptions) {
      const res = await fetch(
        `${url}/cancelSubscription?id=${item.id}&reasons=${cancelReasons}`,
        {
          method: "POST",
        }
      );
      const parsed = await res.json();
    }

    setOtherReason("");
    toast("Successfully canceled plan.", { type: "success" });
    setCancelPlanOpen(false);
    setLoadingResponse(false);
    localStorage.setItem("planPaused", "false");
    setIsPlanPaused(false);
    setResumeDate("");
    getCustomerSubscriptions();
  };

  const resetAddMorePopUp = () => {
    setSelectedQuantity(1);
    setOpenAddNewPopup(false);
    setTimeout(() => {
      setLoadingResponse(false);
    }, 200);
  };

  const [allPlans] = useLazyQuery(PLANS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const plans = [] as any;
      data.products?.edges.filter((item: any) => {
        if (
          item.node.productType === "Gummies" ||
          item.node.title === "Super Pack"
        ) {
          const priceAdjustment =
            item.node.sellingPlanGroups.edges[0].node.sellingPlans.edges[0].node
              .priceAdjustments[0].adjustmentValue.adjustmentPercentage;

          const price = Number(item.node.variants.edges[0].node.priceV2.amount);

          let adjustedPrice = price - (priceAdjustment / 100) * price;
          if (item.node.title === "Super Pack")
            adjustedPrice = price - (18.3333 / 100) * price;

          plans.push({
            id: item.node.id,
            adjustment: priceAdjustment,
            adjustedPrice,
            price,
            title: item.node.title,
          });
        }
      });
      setDiscountedPrices(plans);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const filterType = () => {
    const filteredProducts = allShopifyProduct.edges.filter(
      (el: any) => el.node.productType === "Gummies"
    );
    return filteredProducts;
  };

  const filterAndSetProducts = ({ checkSubscriptions = false }) => {
    const filteredProducts = filterType();

    let finalProducts = filteredProducts;

    if (checkSubscriptions && subscriptions.length) {
      const newProducts = filteredProducts.filter((prod: any) => {
        return !subscriptions.find(
          (subs: any) => subs.product_title === prod.node.title
        );
      });
      finalProducts = newProducts;
    }
    setProducts(finalProducts);
  };

  useEffect(() => {
    if (shouldRefetch) {
      getCustomerSubscriptions();
      setShouldRefetch(false);
    }
  }, [shouldRefetch]);

  useEffect(() => {
    if (newSubItem) {
      const subItem = discountedPrices.filter(
        (item: any) =>
          item.title.toUpperCase() === newSubItem.node.title.toUpperCase()
      )[0];
      setSubscriptionTotal(subItem.adjustedPrice * selectedQuantity);
    }
  }, [selectedQuantity]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);

    allPlans();
    getCustomerSubscriptions();
    if (allShopifyProduct) {
      filterAndSetProducts({});
    }

    const isPaused = localStorage.getItem("planPaused");
    if (isPaused) setIsPlanPaused(true);
  }, []);

  useEffect(() => {
    if (allShopifyProduct) {
      filterAndSetProducts({ checkSubscriptions: true });
    }
    if (subscriptions.length) handleResumePlan();
  }, [subscriptions]);

  return loading ? (
    <div className={s.spinnerContainer}>
      <ClockLoader color={"#16b76a"} loading={true}></ClockLoader>
    </div>
  ) : (
    <>
      <div className={s.mainWrapper}>
        {subscriptions.length > 0 ? (
          <>
            <p className={s.text}>Current plan</p>
            {subscriptions.map((sub: any, index: number) => {
              return (
                <SubscriptionRow
                  discountedPrice={
                    discountedPrices.filter(
                      (item: any) =>
                        sub.product_title.toUpperCase() ===
                        item.title.toUpperCase()
                    )[0].adjustedPrice
                  }
                  subscription={sub}
                  setShouldRefetch={setShouldRefetch}
                  key={index}
                />
              );
            })}

            <div
              className={s.moreSubs}
              onClick={() => setOpenAddNewPopup(true)}
            >
              <img src={plus} alt="plus"></img>
              <p className={s.addMore}>Add more</p>
            </div>

            <div className={s.paymentSchedule}>
              <p className={s.payment}>Payment schedule</p>
              <div className={s.wrapper}>
                <p className={s.description}>
                  {isPlanPlaused
                    ? "Your plan is temporarily paused. Payments will resume on " +
                      resumeDate +
                      "."
                    : "Your next payment is on " +
                      moment(subscriptions[0].next_charge_scheduled_at).format(
                        "MMMM Do YYYY"
                      ) +
                      "."}
                </p>
                {!isPlanPlaused ? (
                  <button
                    className={s.link}
                    onClick={() => setPausePlanOpen(true)}
                  >
                    Pause plan
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className={s.buttonWrapper}>
              <button
                className={s.updateButton}
                onClick={() => setOpenAddNewPopup(true)}
              >
                Update
              </button>
              <button
                className={s.cancelButton}
                onClick={() => setCancelPlanOpen(true)}
              >
                Cancel plan
              </button>
            </div>
          </>
        ) : !addressId ? (
          <div>
            You currently do not have any subscriptions. Add some from our
            products page and you will be able to manage your subscriptions
            here.
          </div>
        ) : (
          <>
            <p className={s.text}>Current plan</p>
            <div
              className={s.moreSubs}
              onClick={() => setOpenAddNewPopup(true)}
            >
              <img src={plus} alt="plus"></img>
              <p className={s.addMore}>Add more</p>
            </div>
          </>
        )}

        <SmallerPopUp
          showIcon={false}
          loading={loadingResponse}
          info={{
            title: "",
            htmlTitle: () => (
              <div style={{ fontFamily: "Neue Radial C Regular" }}>
                ADD NEW ITEM TO{" "}
                <div style={{ fontFamily: "Neue Radial C Bold" }}>
                  YOUR CURRENT PLAN
                </div>
              </div>
            ),
            htmlContent: AddNewSubscriptionForm,
            content: "",
            leftButtonText: "Update",
            rightButtonText: "Cancel",
          }}
          visible={openAddNewPopup}
          handlePopUp={resetAddMorePopUp}
          handleSubscription={updatePlan}
        />
      </div>

      <SmallerPopUp
        visible={pausePlanOpen}
        handlePopUp={() => setPausePlanOpen(false)}
        handleSubscription={handlePausePlan}
        info={{
          title: "",
          htmlTitle: () => (
            <div>
              Pause plan until{" "}
              <div>
                {subscriptions.length
                  ? moment(subscriptions[0].next_charge_scheduled_at)
                      .add(1, "month")
                      .format("MMMM YYYY")
                  : ""}
                ?
              </div>
            </div>
          ),
          content: "",
          htmlContent: () => (
            <div>
              By commiting to this action you agree to pause your subscription
              plan for one month.
            </div>
          ),
          leftButtonText: "Pause plan",
          rightButtonText: "Continue plan",
        }}
      ></SmallerPopUp>

      <SmallerPopUp
        visible={cancelPlanOpen}
        handlePopUp={() => setCancelPlanOpen(false)}
        handleSubscription={handleCancelPlan}
        loading={loadingResponse}
        info={{
          title: "Cancelling plan",
          content: "",
          htmlContent: () => (
            <div>
              <div style={{ marginLeft: "40px" }}>
                By commiting to this action you agree to cancel your
                subscription plan indefinitely.
              </div>
              <div className={s.checkboxes}>
                {checkReasons.map((item) => (
                  <div
                    className={s.checkboxContainer}
                    style={{
                      ...(item.checked && { backgroundColor: "#EEF0F2" }),
                    }}
                    key={item.text}
                  >
                    <input
                      type="checkbox"
                      onChange={(e) => onSelectReason(item, e)}
                      className={s.checkboxInput}
                      checked={item.checked}
                    />
                    <span className={s.checkmark}></span>
                    <p>{item.text}</p>
                  </div>
                ))}
              </div>

              <div className={s.feedback}>
                <div>Other</div>
                <textarea
                  placeholder="Enter feedback"
                  onChange={(e) => setOtherReason(e.target.value)}
                />
              </div>
            </div>
          ),
          leftButtonText: "Cancel plan",
          rightButtonText: "Continue plan",
        }}
      ></SmallerPopUp>
    </>
  );
};

export default Subscriptions;
