// extracted by mini-css-extract-plugin
export var card = "AccInfo-module--card--hbWaO";
export var closeImg = "AccInfo-module--close-img--TwaZh";
export var container = "AccInfo-module--container--b1ltx";
export var hoverText = "AccInfo-module--hover-text--U1KKg";
export var iconFormWrapper = "AccInfo-module--icon-form-wrapper--WAjL2";
export var imageWrapper = "AccInfo-module--image-wrapper--zH7nC";
export var inputText = "AccInfo-module--input-text--TlUdZ";
export var inputWrapper = "AccInfo-module--input-wrapper--yvIbe";
export var profileWrapper = "AccInfo-module--profile-wrapper--KTAZ0";
export var spanWrapper = "AccInfo-module--span-wrapper--CjAyq";
export var text = "AccInfo-module--text--WkP5f";
export var textWrapper = "AccInfo-module--text-wrapper--Qtd88";
export var wrapper = "AccInfo-module--wrapper--Q0lr1";