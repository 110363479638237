import React, { useEffect } from "react";
import AccInfo from "../../atoms/AccInfo/AccInfo";
import Account from "../AccountProfile/Account";
import OrderHistory from "../OrderHistory/OrderHistory";
import OrdersProfile from "../OrdersProfile/OrdersProfile";
import Security from "../Security/Security";
import Subscriptions from "../Subcriptions/Subscriptions";
import * as s from "./ProfilePageContent.module.scss";

const ProfilePageContent = ({
  activeTitle,
  customer,
  checkoutId,
  token,
  setActiveOrder,
  ordersProfileActive,
  activeOrder,
  setOrdersProfileActive,
}: any) => {
  useEffect(() => {
    console.log("customer", customer);
  }, []);
  return (
    <>
      {activeTitle === "Account" ? (
        <Account customer={customer} checkoutId={checkoutId} token={token} />
      ) : activeTitle === "Orders" ? (
        ordersProfileActive === "order profile" ? (
          <OrdersProfile
            customer={customer}
            setActiveOrder={setActiveOrder}
            setOrdersProfileActive={setOrdersProfileActive}
          />
        ) : ordersProfileActive === "single order" &&
          activeOrder !== undefined ? (
          <OrderHistory order={activeOrder} />
        ) : null
      ) : activeTitle === "Billing" ? (
        <div className={s.accountWrapper}>
          {/* <div>
            <p className={s.accountInfo}>Payment Information</p>
            <AccInfo cardnr="**** **** **** ****" activeComponent="payment" />
          </div> */}
          <div>
            <p className={s.accountInfo}>Billing Information</p>
            <AccInfo
              address={customer?.defaultAddress?.address1}
              city={customer?.defaultAddress?.city}
              zip={customer?.defaultAddress?.zip}
              state={customer?.defaultAddress?.country}
              activeComponent="billing"
              token={token}
            />
          </div>
        </div>
      ) : activeTitle === "Security" ? (
        <Security />
      ) : activeTitle === "Subscriptions" && customer ? (
        <Subscriptions customer={customer} />
      ) : null}
    </>
  );
};

export default ProfilePageContent;
