/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */

import { navigate } from "gatsby-link";
import React from "react";
import lightbulb from "../../../images/Light bulb.svg";
import * as s from "./Security.module.scss";

const Security = () => {
  return (
    <div className={s.mainWrapper}>
      <p className={s.text}>Reset password via email</p>
      <div className={s.container}>
        <div>
          <img src={lightbulb} alt="lightbulb"></img>
        </div>
        <div className={s.textWrapper}>
          <p className={s.title}>Forgot your password?</p>
          <p className={s.description}>
            Click on the reset button below and we’ll send a temporary password
            to your confirmed email.
          </p>
          <div className={s.link} onClick={() => navigate("/forgot-password")}>
            Send reset link
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;
