/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */

import React, { useState } from "react";
import * as s from "./AccInfo.module.scss";
import close from "../../../images/closeX.svg";
import EditProfile from "../../organisms/EditProfile/EditProfile";
import CheckoutForm from "../../molecules/CheckoutForm/CheckoutForm";
import Payment from "../../molecules/Payment/Payment";

interface IAddress {
  defaultAddress: {
    address1: string;
    address2: string;
    city: string;
    country: string;
    province: string;
    zip: string;
  };
}

interface IInfo {
  name?: string;
  lastname?: string;
  email?: string;
  cardnr?: string;
  address?: string;
  city?: string;
  zip?: string;
  state?: string;
  phone?: string;
  customer?: TroopState.User & IAddress;
  checkoutId?: string | null;
  activeComponent?: string;
  token?: string | null;
}

const AccInfo = ({ customer, checkoutId, activeComponent, token }: IInfo) => {
  const [active, setActive] = useState(true);
  const [component, setComponent] = useState(true);
  const [checked, setChecked] = useState(false);

  const handleClick = () => {
    setActive(!active);
    setComponent(!component);
  };

  return (
    <div className={s.container}>
      {component ? (
        <div className={s.iconFormWrapper}>
          <div className={s.profileWrapper}>
            {activeComponent === "edit" ? (
              <EditProfile customer={customer} />
            ) : activeComponent === "shipping" && token ? (
              <CheckoutForm checkoutId={checkoutId} token={token} />
            ) : activeComponent === "payment" ? (
              <Payment />
            ) : activeComponent === "billing" && token ? (
              <>
                <div className={s.inputWrapper}>
                  <input type="checkbox" onClick={() => setChecked(!checked)} />
                  <p className={s.inputText}>Same as shipping address</p>
                </div>
                {!checked ? (
                  <CheckoutForm checkoutId={checkoutId} token={token} />
                ) : null}
              </>
            ) : null}
          </div>

          <div>
            {!active ? (
              <img
                src={close}
                alt="close"
                onClick={() => handleClick()}
                className={s.closeImg}
              ></img>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AccInfo;
