import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ProfilePage from "../components/organisms/ProfilePage/ProfilePage";
import { GET_USER } from "../g/queries/get-user-info";
import { userConstants } from "../modules/auth/constants";
import { PageTitle, SEO } from "../modules/seo";

const Profile = () => {
  const [token, setToken] = useState<string | null>(null);
  const [checkoutId, setCheckoutId] = useState<string | null>(null);

  // get user data
  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    setCheckoutId(localStorage.getItem("checkoutId"));
  }, []);

  const { data } = useQuery(GET_USER, {
    variables: {
      customerAccessToken: token,
    },
    onCompleted: (data) => {
      setCheckoutId(data?.customer?.lastIncompleteCheckout?.id);
    },
  });

  const dispatch = useDispatch();

  dispatch({
    type: userConstants.GETALL_SUCCESS,
    user: data?.customer,
  });

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <SEO title={PageTitle.Profile} />
      <ProfilePage
        customer={data?.customer}
        checkoutId={checkoutId}
        token={token}
      />
    </div>
  );
};

export default Profile;
