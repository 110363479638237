// extracted by mini-css-extract-plugin
export var firstRow = "OrdersProfile-module--first-row--K55Dz";
export var invoice = "OrdersProfile-module--invoice--j-ESd";
export var name = "OrdersProfile-module--name--iiuhV";
export var orderText = "OrdersProfile-module--order-text--8YGsF";
export var orderWrapper = "OrdersProfile-module--order-wrapper--BpbG-";
export var text = "OrdersProfile-module--text--4SyX4";
export var viewMore = "OrdersProfile-module--view-more--6b+iM";
export var widthFive = "OrdersProfile-module--width-five--puP87";
export var widthFour = "OrdersProfile-module--width-four--t0Khk";
export var widthOne = "OrdersProfile-module--width-one--7E8I+";
export var widthThree = "OrdersProfile-module--width-three--3AKBQ";
export var widthTwo = "OrdersProfile-module--width-two--8Ee9G";