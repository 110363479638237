import { useMutation } from "@apollo/client";
import React, { useEffect } from "react";
import { useState } from "react";
import FormProvider from "../../../forms/FormProvider";
import {
  CHANGE_USER_ADDRESS,
  EDIT_PROFILE_MUTATION,
  UPDATE_USER_ADDRESS,
} from "../../../g/mutations/customer";
import { pick } from "lodash";
import { toast } from "react-toastify";

interface IAddress {
  defaultAddress: {
    address1: string;
    address2: string;
    city: string;
    country: string;
    province: string;
    zip: string;
  };
}

interface IUserInfoProp {
  customer?: TroopState.User & IAddress;
}

const EditProfile = ({ customer }: IUserInfoProp) => {
  const [editData, setEditData] =
    useState<TroopState.EndpointPayload | null>(null);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
  }, []);

  useEffect(() => {
    if (customer && !editData) {
      setEditData(pick(customer, ["firstName", "lastName", "email"]));
    }
  }, [customer]);

  const [newUser] = useMutation(EDIT_PROFILE_MUTATION, {
    variables: {
      token: token,
      input: editData,
    },
    onError: (err) => {
      console.log(err);
    },
    onCompleted: (data) => {
      if (!data.customerUpdate.customer)
        toast(data.customerUpdate.userErrors[0].message, { type: "error" });
      else
        toast("Your personal information has been updated.", {
          type: "success",
        });
    },
  });

  const handleSubmit = (data: TroopState.EndpointPayload) => {
    setEditData(pick(data, ["email", "firstName", "lastName"]));
    newUser();
  };

  return (
    <div>
      {editData ? (
        <FormProvider
          formKey="edit"
          initialValues={editData}
          formSubmit={handleSubmit}
          buttonText="Update"
          leftSide={true}
        ></FormProvider>
      ) : (
        <></>
      )}
    </div>
  );
};

export default EditProfile;
