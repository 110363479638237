// extracted by mini-css-extract-plugin
export var addMore = "Subscriptions-module--add-more--ACwHS";
export var buttonWrapper = "Subscriptions-module--button-wrapper--FH0P+";
export var cancelButton = "Subscriptions-module--cancel-button--7c0Gn";
export var checkboxContainer = "Subscriptions-module--checkboxContainer--wevN7";
export var checkboxGreenText = "Subscriptions-module--checkboxGreenText--4TprA";
export var checkboxText = "Subscriptions-module--checkboxText--oK5DP";
export var checkboxes = "Subscriptions-module--checkboxes--iQcOc";
export var checkmark = "Subscriptions-module--checkmark--fPA2Z";
export var description = "Subscriptions-module--description--KWdJz";
export var downArrow = "Subscriptions-module--down-arrow--PCBfs";
export var feedback = "Subscriptions-module--feedback--5E2Dl";
export var label = "Subscriptions-module--label--UkjgI";
export var link = "Subscriptions-module--link--oYSLo";
export var mainWrapper = "Subscriptions-module--mainWrapper--5zrEm";
export var menuItem = "Subscriptions-module--menu-item--7aPxK";
export var menuItemContainer = "Subscriptions-module--menu-item-container--dIguX";
export var menuItemPrice = "Subscriptions-module--menu-item-price--pyU+z";
export var menuItemWrapper = "Subscriptions-module--menu-item-wrapper--mpVlh";
export var moreSubs = "Subscriptions-module--more-subs--YAXT3";
export var payment = "Subscriptions-module--payment--OzPvh";
export var paymentSchedule = "Subscriptions-module--payment-schedule--yrASa";
export var productsContent = "Subscriptions-module--products-content--FwzQ2";
export var separator = "Subscriptions-module--separator--r4Mf8";
export var spinnerContainer = "Subscriptions-module--spinner-container--WkAim";
export var subscriptionSelect = "Subscriptions-module--subscription-select--I+NQ6";
export var text = "Subscriptions-module--text--vrCCZ";
export var totalContainer = "Subscriptions-module--total-container--3VcIc";
export var totalPrice = "Subscriptions-module--total-price--5Sh6x";
export var updateButton = "Subscriptions-module--update-button--GNpId";
export var wrapper = "Subscriptions-module--wrapper--uuDCj";