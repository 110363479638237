// extracted by mini-css-extract-plugin
export var buttonWrapper = "SmallerPopUp-module--button-wrapper--KR7mY";
export var close = "SmallerPopUp-module--close--yrmPa";
export var container = "SmallerPopUp-module--container--F3-Pg";
export var content = "SmallerPopUp-module--content--ITe-g";
export var icon = "SmallerPopUp-module--icon--TCNmN";
export var leftButton = "SmallerPopUp-module--left-button--7jafo";
export var popup = "SmallerPopUp-module--popup--S4V33";
export var popupOverlay = "SmallerPopUp-module--popup-overlay--BGJFf";
export var popupOverlayShow = "SmallerPopUp-module--popup-overlay-show--Xd6lx";
export var popupTitle = "SmallerPopUp-module--popup-title--SkU6y";
export var rightButton = "SmallerPopUp-module--right-button--42IdN";
export var spinnerContainer = "SmallerPopUp-module--spinner-container--CMkmr";
export var title = "SmallerPopUp-module--title--6pZ+4";