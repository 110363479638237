/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import * as s from "./SmallerPopUp.module.scss";
import classNames from "classnames";
import warning from "../../../images/warning.svg";
import closeModal from "../../../images/X close.svg";
import ClockLoader from "react-spinners/ClockLoader";

interface IStyle {
  title: any;
}

interface PopUpProps {
  info?: IPopUpContent;
  visible: boolean;
  handlePopUp: (a: boolean) => void;
  handleSubscription: () => void;
  showIcon?: boolean;
  style?: IStyle;
  loading?: boolean;
}

const SmallerPopUp = ({
  info,
  visible = false,
  handlePopUp,
  handleSubscription,
  showIcon = true,
  loading = false,
}: PopUpProps) => {
  const cn = classNames.bind(s);

  const handle = () => {
    handlePopUp(false);
  };

  return (
    <div
      id="#popup1"
      className={cn(s.popupOverlay, { [s.popupOverlayShow]: visible })}
    >
      <div className={s.popup}>
        <div className={s.container}>
          <div className={s.popupTitle}>
            <div className={s.title}>
              {showIcon && (
                <img className={s.icon} src={warning} alt="warning"></img>
              )}
              {info?.title}
              {info?.htmlTitle ? info?.htmlTitle() : ""}
            </div>
            {loading ? (
              <></>
            ) : (
              <img
                className={s.close}
                src={closeModal}
                alt="close"
                onClick={handle}
              />
            )}
          </div>

          {loading ? (
            <div className={s.spinnerContainer}>
              <ClockLoader
                color={"#16b76a"}
                loading={true}
                size={30}
              ></ClockLoader>
            </div>
          ) : (
            <>
              <div className={s.content}>
                {info?.content}
                {info?.htmlContent ? info.htmlContent() : ""}
              </div>
              <div className={s.buttonWrapper}>
                <button onClick={handleSubscription} className={s.leftButton}>
                  {info?.leftButtonText}
                </button>
                <button onClick={handle} className={s.rightButton}>
                  {info?.rightButtonText}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SmallerPopUp;
