/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */

import React, { useEffect, useState } from "react";
import Quantity from "../Quantity/Quantity";
import * as s from "./SubscriptionRow.module.scss";
import update from "../../../images/update.svg";
import cancel from "../../../images/cancel.svg";
import superT from "../../../images/superTroopWithBe.png";
import lionsM from "../../../images/lionsManeWithBe.png";
import reishi from "../../../images/reishiWithBe.png";
import SmallerPopUp from "../SmallerPopUp/SmallerPopUp";
import classNames from "classnames";
import { toast } from "react-toastify";

const cn = classNames.bind(s);

const SubscriptionRow = ({
  subscription,
  setShouldRefetch,
  discountedPrice,
}: any) => {
  const { product_title, quantity, id, status } = subscription;
  const [productStatus, setProductStatus] = useState(status);
  const [newQuantity, setNewQuantity] = useState(quantity);
  const [popUp, showPopUp] = useState(false);
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);

  const body1 = JSON.stringify({
    quantity: newQuantity,
  });

  const handleUpdate = () => {
    const url = window.location.href.replace("profile", ".netlify/functions");
    fetch(`${url}/updateSubscription?id=${id}`, {
      method: "PUT",
      body: body1,
    })
      .then((res) => res.text())
      .then((text) => {
        const subs = JSON.parse(text);
        toast("Subscription updated", { type: "success" });
      });
  };

  const handleCancel = () => {
    setLoading(true);
    const url = window.location.href.replace("profile", ".netlify/functions");
    fetch(`${url}/cancelSubscription?id=${id}`, {
      method: "POST",
    })
      .then((res) => res.text())
      .then((text) => {
        const cancel = JSON.parse(text);
        setProductStatus(cancel?.subscription?.status);
        showPopUp(false);
        setShouldRefetch(true);
        setLoading(false);
      });
  };

  const handlePopUp = (visible: boolean) => {
    showPopUp(visible);
  };

  const handleClick = () => {
    showPopUp(true);
  };

  const popUpInfo = {
    title: "Removing item from plan",
    content:
      "By commiting to this action you agree to remove this item from your subscription.",
    leftButtonText: "Remove item",
    rightButtonText: "Cancel",
  };

  useEffect(() => {
    if (product_title.toLowerCase().includes("reishi")) setImage(reishi);
    else if (product_title.toLowerCase().includes("super")) setImage(superT);
    else setImage(lionsM);
  }, [product_title]);

  return (
    <>
      {productStatus === "ACTIVE" ? (
        <div className={s.mainWrapper}>
          <div className={s.imageWrapper}>
            {" "}
            <img src={image} alt="productImg"></img>
            <p className={s.title}>{product_title}</p>
          </div>

          <div className={s.contentWrapper}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Quantity
                newQuantity={newQuantity}
                setNewQuantity={setNewQuantity}
              />
              <p className={s.price}>
                ${Number(discountedPrice * newQuantity).toFixed(2)}
              </p>
            </div>
            <div className={cn(s.imageWrapper, s.newImageWrapper)}>
              {" "}
              <div className={s.spanWrapper}>
                <img
                  src={update}
                  alt="update"
                  onClick={() => handleUpdate()}
                ></img>
                <span className={s.hoverText}>Update order</span>
              </div>
              <div className={s.spanWrapper}>
                {" "}
                <img src={cancel} alt="cancel" onClick={handleClick}></img>
                <span className={s.hoverText}>Cancel anytime</span>
              </div>
            </div>
            <SmallerPopUp
              info={popUpInfo}
              visible={popUp}
              handlePopUp={handlePopUp}
              handleSubscription={handleCancel}
              loading={loading}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SubscriptionRow;
