import User from "../images/User.svg";
import Shopping from "../images/Shopping bag.svg";
import Document from "../images/Document.svg";
import Badge from "../images/Badge.svg";
import Lock from "../images/Lock closed.svg";
import Logout from "../images/Logout.svg";

export const accoutnMenu = [
  {
    title: "Account",
    subtitle: "Name, email and shipping",
    icon: User,
  },

  {
    title: "Orders",
    subtitle: "Orders and history",
    icon: Shopping,
  },

  {
    title: "Billing",
    subtitle: "Set up payment methods",
    icon: Document,
  },

  {
    title: "Subscriptions",
    subtitle: "Manage subscriptions",
    icon: Badge,
  },

  {
    title: "Security",
    subtitle: "Password and security",
    icon: Lock,
  },
  // Hide this
  /*{
    title: "Logout",
    subtitle: "",
    icon: Logout,
  },*/
];
