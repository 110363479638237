// extracted by mini-css-extract-plugin
export var account = "ProfilePage-module--account--3GdEr";
export var accountInfo = "ProfilePage-module--account-info--dPJMM";
export var accountWrapper = "ProfilePage-module--account-wrapper--ffyNz";
export var cartWrapper = "ProfilePage-module--cart-wrapper--oLJtT";
export var container = "ProfilePage-module--container--awMaA";
export var contentWrapper = "ProfilePage-module--content-wrapper--HMTxL";
export var orderText = "ProfilePage-module--order-text--SB-YJ";
export var orderWrapper = "ProfilePage-module--order-wrapper--ctkaF";
export var shrooms = "ProfilePage-module--shrooms--10yFt";
export var text = "ProfilePage-module--text--swsTR";
export var viewMore = "ProfilePage-module--view-more--ztmuu";
export var welcomeText = "ProfilePage-module--welcome-text--Am3Cs";