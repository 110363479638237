import { useQuery } from "@apollo/client";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import AddReviewPopup from "../../molecules/AddReviewPopup/AddReviewPopup";
import * as s from "./Order.module.scss";

interface OneOrder {
  quantity: number;
  title: string;
  variant: {
    id: string;
    priceV2: TroopState.PriceProps;
    image: TroopState.ImagesProps;
    product: {
      handle: string;
    };
  };
}
interface IOrder {
  key: number;
  order: OneOrder;
}

const Order = ({ order }: IOrder) => {
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const handleClick = () => {
    setIsReviewModalOpen(true);
  };

  return (
    <>
      <div className={s.container}>
        <div className={s.orderWrapper}>
          <p className={s.title}>{order?.title}</p>
        </div>
        <div className={s.wrapper}>
          <p className={s.quantity}>{order?.quantity}</p>
          <p className={s.price}>
            {order?.variant?.priceV2?.currencyCode.replace("USD", "$")}{" "}
            {order?.variant?.priceV2?.amount}
          </p>
        </div>
        <div className={s.buttonWrapper}>
          <button onClick={() => handleClick()}>Review</button>
        </div>
      </div>

      <AddReviewPopup
        product={{
          id: order.variant?.id,
          title: order.title,
          images: [order.variant?.image, order.variant?.image],
          description: "",
        }}
        visible={isReviewModalOpen}
        handlePopUp={() => {
          setIsReviewModalOpen(false);
        }}
      />
    </>
  );
};
export default Order;
