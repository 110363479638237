import React, { useEffect, useState } from "react";
import Order from "../../atoms/Order/Order";
import * as s from "./OrderHistory.module.scss";
import truck from "../../../images/truck.svg";
import OrderAgain from "../../atoms/OrderAgainButton/OrderAgain";
import arrow from "../../../images/chevron-right.svg";

interface IOrder {
  id: string;
  lineItems: TroopState.LineItems;
  name: string;
  processedAt: string;
  shippingAddress: TroopState.ShippingAddress;
  totalPriceV2: TroopState.PriceProps;
  processedAd: string;
  financialStatus: string;
  fulfillmentStatus: string;
}

const OrderHistory = ({ order }: { order: IOrder }) => {
  const [orderDay, setOrderDay] = useState<number>();
  const [orderMonth, setOrderMonth] = useState<string>();
  const [orderYear, setOrderYear] = useState<number>();

  useEffect(() => {
    const date = new Date(order?.processedAt);
    setOrderDay(date.getDate());
    setOrderMonth(date.toLocaleString("default", { month: "long" }));
    setOrderYear(date.getFullYear());
  }, [order]);

  return (
    <div className={s.container}>
      <div className={s.textWrapper}>
        <p className={s.text}>Order history</p>
        <img src={arrow} alt="arrow"></img>
        <p className={s.textOrder}>Order {order?.name}</p>
      </div>
      <div className={s.orderWrapper}>
        <div className={s.shippingDiv}>
          <img src={truck} alt=""></img>
          <p>{order?.fulfillmentStatus}</p>
        </div>
        <p className={s.date}>
          {orderDay} {orderMonth}, {orderYear}
        </p>
      </div>

      <div className={s.shippingWrapper}>
        <div>
          <p>
            {order?.shippingAddress?.address1}, {order?.shippingAddress?.zip},
            {order?.shippingAddress?.city}
          </p>
        </div>
      </div>

      {order?.lineItems?.edges.map((item: any, index: number) => {
        return <Order key={index} order={item?.node} />;
      })}

      <div className={s.totalWrapper}>
        <div className={s.buttonWrapper}>
          <p>
            <span>Total:</span>{" "}
            {order?.totalPriceV2?.currencyCode.replace("USD", "$")}{" "}
            {order?.totalPriceV2?.amount}
          </p>
        </div>
      </div>

      <OrderAgain order={order?.lineItems?.edges} />
    </div>
  );
};

export default OrderHistory;
