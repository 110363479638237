import React from "react";
import { accoutnMenu } from "../../../constants/accountMenu";
import AccMenuCard from "../../atoms/AccMenuCard/AccMenuCard";
import * as s from "./AccMenu.module.scss";

interface IAddress {
  defaultAddress: {
    address1: string;
    address2: string;
    city: string;
    country: string;
    province: string;
    zip: string;
    phone: string;
  };
  orders: any;
}

interface IProp {
  title: string;
  subtitle: string;
  icon: string;
}

const AccMenu = ({
  setActiveComponent,
  activeComponent,
  setOrdersProfileActive,
  customer,
  checkoutId,
  token,
  ordersProfileActive,
  activeOrder,
  setActiveOrder,
}: {
  setActiveComponent: (a: string) => void;
  activeComponent: string;
  setOrdersProfileActive: (a: string) => void;
  customer?: TroopState.User & IAddress;
  checkoutId: string | null;
  token: string | null;
  ordersProfileActive?: string;
  activeOrder?: any;
  setActiveOrder?: any;
}) => {
  return (
    <div className={s.mainWrapper}>
      {accoutnMenu.map((i: IProp, index: number) => {
        return (
          <AccMenuCard
            title={i.title}
            subtitle={i.subtitle}
            icon={i.icon}
            setActiveComponent={setActiveComponent}
            activeComponent={activeComponent}
            setOrdersProfileActive={setOrdersProfileActive}
            key={index}
            customer={customer}
            token={token}
            checkoutId={checkoutId}
            ordersProfileActive={ordersProfileActive}
            activeOrder={activeOrder}
            setActiveOrder={setActiveOrder}
          />
        );
      })}
    </div>
  );
};

export default AccMenu;
