import React from "react";
import * as s from "./OrdersProfile.module.scss";
import SingleOrder from "../../atoms/SingleOrder/SingleOrder";
import classNames from "classnames";

interface IAddress {
  defaultAddress: {
    address1: string;
    address2: string;
    city: string;
    country: string;
    province: string;
    zip: string;
    phone: string;
  };
  orders: any;
}

interface IProp {
  customer?: TroopState.User & IAddress;
  setActiveOrder: (a: any) => void;
  setOrdersProfileActive: (a: string) => void;
}

const cn = classNames.bind(s);

const OrdersProfile = ({
  customer,
  setActiveOrder,
  setOrdersProfileActive,
}: IProp) => {
  return (
    <div className={s.orderWrapper}>
      <p className={s.text}>Order history</p>
      <div className={s.firstRow}>
        <p className={cn(s.name, s.widthOne)}>Order</p>
        <p className={cn(s.name, s.widthTwo)}>Date</p>
        <p className={cn(s.name, s.widthThree)}>Payment</p>
        <p className={cn(s.name, s.widthFour)}>Status</p>
        <p className={cn(s.name, s.widthFive)}>Total</p>
      </div>
      {customer?.orders?.edges?.length > 0 &&
        customer?.orders?.edges.map((order: any, index: number) => {
          return (
            <SingleOrder
              order={order?.node}
              setActiveOrder={setActiveOrder}
              setOrdersProfileActive={setOrdersProfileActive}
              key={index}
            />
          );
        })}
    </div>
  );
};

export default OrdersProfile;
