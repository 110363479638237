// extracted by mini-css-extract-plugin
export var buttonWrapper = "OrderHistory-module--button-wrapper--kyJ4F";
export var container = "OrderHistory-module--container--7CWwC";
export var date = "OrderHistory-module--date--LQIsS";
export var orderNumber = "OrderHistory-module--order-number--lDlv5";
export var orderWrapper = "OrderHistory-module--order-wrapper--fefj9";
export var shippingDiv = "OrderHistory-module--shipping-div--xlIaM";
export var shippingWrapper = "OrderHistory-module--shipping-wrapper--eCb27";
export var text = "OrderHistory-module--text--yY6ok";
export var textOrder = "OrderHistory-module--text-order--a8eCt";
export var textWrapper = "OrderHistory-module--text-wrapper--Z5-ft";
export var totalWrapper = "OrderHistory-module--total-wrapper--AeOdG";