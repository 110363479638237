import React from "react";
import FormProvider from "../../../forms/FormProvider";
const Payment = () => {
  return (
    <div>
      <FormProvider formKey="payment" buttonText="Update" leftSide={true} />
    </div>
  );
};

export default Payment;
