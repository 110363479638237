/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */

import classNames from "classnames";
import { navigate } from "gatsby-link";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { userConstants } from "../../../modules/auth/constants";
import { cartConstants } from "../../../modules/cart/constants";
import up from "../../../images/arrowUp.svg";
import down from "../../../images/downA.svg";
import * as s from "./AccMenuCard.module.scss";
import ProfilePageContent from "../../molecules/ProfilePageContent/ProfilePageContent";

interface IAddress {
  defaultAddress: {
    address1: string;
    address2: string;
    city: string;
    country: string;
    province: string;
    zip: string;
    phone: string;
  };
  orders: any;
}

interface IProp {
  title: string;
  subtitle: string;
  icon: string;
  setActiveComponent: (a: string) => void;
  activeComponent: string;
  setOrdersProfileActive: (a: string) => void;
  customer?: TroopState.User & IAddress;
  checkoutId: string | null;
  token: string | null;
  ordersProfileActive?: string;
  activeOrder?: any;
  setActiveOrder?: any;
}

const cn = classNames.bind(s);

const AccMenuCard = ({
  title,
  subtitle,
  icon,
  setActiveComponent,
  activeComponent,
  setOrdersProfileActive,
  customer,
  checkoutId,
  token,
  ordersProfileActive,
  activeOrder,
  setActiveOrder,
}: IProp) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);

  const userLogout = () => {
    dispatch({ type: userConstants.LOGOUT });
    localStorage.removeItem("token");
    dispatch({ type: cartConstants.EMPTY_CART });
    localStorage.removeItem("checkoutId");
    navigate("/");
  };

  const handleClick = () => {
    if (title === "Orders") setOrdersProfileActive("order profile");
    if (title === "Logout") {
      userLogout();
    }
    setActiveComponent(title);
    setActive(!active);
  };

  useEffect(() => {
    if (window.screen.width <= 768) setActiveComponent("");
  }, []);

  return (
    <>
      <div
        className={cn(s.mainWrapper, {
          [s.activeWrapper]: activeComponent === title,
        })}
        onClick={() => handleClick()}
      >
        <div className={s.imageTextWrapper}>
          <img src={icon} alt="icon"></img>

          <div className={s.textWrapper}>
            <p className={s.title}>{title}</p>
            <p className={s.subtitle}>{subtitle}</p>
          </div>
        </div>

        {typeof window !== "undefined" && window.screen.width <= 768 ? (
          <div>
            {active ? (
              <img src={up} alt="up"></img>
            ) : (
              <img src={down} alt="down" className={s.arrow}></img>
            )}
          </div>
        ) : null}
      </div>

      {typeof window !== "undefined" && window.screen.width <= 768 && active ? (
        <div className={s.activeComponentWrapper}>
          <ProfilePageContent
            activeTitle={title}
            customer={customer}
            checkoutId={checkoutId}
            token={token}
            setActiveOrder={setActiveOrder}
            ordersProfileActive={ordersProfileActive}
            activeOrder={activeOrder}
            setOrdersProfileActive={setOrdersProfileActive}
          />
        </div>
      ) : null}
    </>
  );
};

export default AccMenuCard;
